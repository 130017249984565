import {
    ALL_LANGUAGE,
    ALL_SEX,
    CustomFieldWithConditionFragment,
    EventsField,
    FieldProperty,
    FieldType
} from 'common/src/generated/types';
import { CountriesService } from 'common/src/services/countriesService';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { USABLE_LANGUAGES } from 'common/src/vo/supportedLanguage';
import * as React from 'react';
import { useEnumToOptions } from '../../../hooks/useEnumToOptions';
import { Accept } from '../../../util/accept';
import { useMediaQueryContext } from '../../mediaQuery/mediaQueryContext';
import { AddressInput } from '../../reactFinalForm/input/address';
import { Checkbox } from '../../reactFinalForm/input/checkbox';
import { DateInput } from '../../reactFinalForm/input/date';
import { FileInput } from '../../reactFinalForm/input/file';
import { UploaderMode } from '../../reactFinalForm/input/file/uploader';
import { PhoneInput } from '../../reactFinalForm/input/phone';
import { Select } from '../../reactFinalForm/input/select';
import { SelectSearch } from '../../reactFinalForm/input/selectSearch/selectSearch';
import { TextInput } from '../../reactFinalForm/input/text';
import { Textarea } from '../../reactFinalForm/input/textarea';
import { TimeInput } from '../../reactFinalForm/input/time';

interface IFieldProps {
    eventField: Pick<EventsField, 'isMandatory'> & {
        field: CustomFieldWithConditionFragment;
    };
    prefix: string;
}

export const Field = (props: IFieldProps) => {
    const translate = useTranslate();
    const enumToOptions = useEnumToOptions();
    const countriesService = useService(CountriesService);
    const { isMobile } = useMediaQueryContext();
    const isOptional = !props.eventField.isMandatory;
    const field = props.eventField.field;
    const name = `${props.prefix}${field.slug}`;
    const label = field.name;
    const subtitle = props.eventField.field.description;

    switch (field.fieldType) {
        case FieldType.Text:
            return (
                <TextInput label={label} subtitle={subtitle} name={name} isOptional={isOptional} />
            );
        case FieldType.Textarea:
            return (
                <Textarea label={label} subtitle={subtitle} name={name} isOptional={isOptional} />
            );
        case FieldType.Checkbox:
            return <Checkbox name={name} subtext={subtitle} text={label} />;
        case FieldType.Validation:
            return (
                <Checkbox
                    name={name}
                    text={label}
                    subtext={
                        <>
                            {isNonEmptyString(subtitle) && <span>{subtitle}</span>}

                            {isNonEmptyString(subtitle) && field.document && <br />}

                            {field.document && (
                                <a href={field.document.url} rel="noreferrer" target="_blank" >
                                    {translate('consulter_le_do_69928')}
                                </a>
                            )}
                        </>
                    }
                />
            );
        case FieldType.Select:
            if (field.canSelectMultiple) {
                return (
                    <SelectSearch
                        label={label}
                        subtitle={subtitle}
                        isOptional={isOptional}
                        name={name}
                        canCreateValue={false}
                        options={field.values.map(({ id, value }) => ({
                            id,
                            name: value
                        }))}
                    />
                );
            } else {
                return (
                    <Select
                        label={label}
                        subtitle={subtitle}
                        name={name}
                        isOptional={isOptional}
                        parseInt={true}
                    >
                        {field.values.map(({ id, value }) => (
                                <option key={id} value={id}>
                                    {value}
                                </option>
                            ))}
                    </Select>
                );
            }
        case FieldType.Date:
            return (
                <DateInput label={label} subtitle={subtitle} name={name} isOptional={isOptional} />
            );
        case FieldType.Datetime:
            return null;
        case FieldType.Time:
            return (
                <TimeInput label={label} subtitle={subtitle} name={name} isOptional={isOptional} />
            );
        case FieldType.File:
            if (field.fieldProperty === FieldProperty.Picture && !isMobile) {
                return (
                    <FileInput
                        acl={'public-read'}
                        accept={Accept.Images}
                        mode={UploaderMode.Image}
                        size={100}
                        label={label}
                        prefix={`${name}.`}
                        isOptional={isOptional}
                    />
                );
            } else {
                return (
                    <FileInput
                        acl="private"
                        accept={[Accept.Images, Accept.Pdf]}
                        mode={UploaderMode.Simple}
                        label={label}
                        subtext={subtitle}
                        prefix={`${name}.`}
                        isOptional={isOptional}
                    />
                );
            }
        case FieldType.Phone:
            return (
                <PhoneInput
                    label={label}
                    subtitle={subtitle}
                    prefix={`${name}.`}
                    isOptional={isOptional}
                />
            );
        case FieldType.Number:
            return (
                <TextInput
                    label={label}
                    subtitle={subtitle}
                    name={name}
                    parseInt={true}
                    isOptional={isOptional}
                />
            );
        case FieldType.Sex:
            return (
                <Select label={label} name={name} isOptional={isOptional}>
                    {enumToOptions(ALL_SEX)}
                </Select>
            );
        case FieldType.Language:
            if (field.fieldProperty === FieldProperty.Language) {
                return (
                    <Select label={label} subtitle={subtitle} name={name} isOptional={isOptional}>
                        {enumToOptions(USABLE_LANGUAGES)}
                    </Select>
                );
            } else {
                return (
                    <Select label={label} subtitle={subtitle} name={name} isOptional={isOptional}>
                        {enumToOptions(ALL_LANGUAGE)}
                    </Select>
                );
            }
        case FieldType.Nationality:
            if (isMobile) {
                return (
                    <Select label={label} name={name} isOptional={isOptional}>
                        {countriesService.nationalitiesIdName.map((idName) => (
                                <option value={idName.id} key={idName.id}>
                                    {idName.name}
                                </option>
                            ))}
                    </Select>
                );
            } else {
                return (
                    <SelectSearch
                        label={label}
                        isOptional={isOptional}
                        name={name}
                        canCreateValue={false}
                        options={countriesService.nationalitiesIdName}
                    />
                );
            }
        case FieldType.Address:
            return (
                <AddressInput
                    addressName={name}
                    placeName="place"
                    label={label}
                    isOptional={isOptional}
                />
            );
        case FieldType.Country:
            if (isMobile) {
                return (
                    <Select label={label} name={name} isOptional={isOptional}>
                        {countriesService.countriesIdName.map((idName) => (
                                <option value={idName.id} key={idName.id}>
                                    {idName.name}
                                </option>
                            ))}
                    </Select>
                );
            } else {
                return (
                    <SelectSearch
                        label={label}
                        isOptional={isOptional}
                        name={name}
                        canCreateValue={false}
                        options={countriesService.countriesIdName}
                    />
                );
            }
        default:
            return assertUnreachable(field.fieldType);
    }
};
