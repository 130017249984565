import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { noop } from 'lodash-es';
import * as React from 'react';
import { Checkbox } from '../checkbox';

interface IRichSelectItemProps {
    children: string;
    isCheckbox: boolean;
    isSelected: boolean;
    // eslint-disable-next-line react/no-unused-prop-types
    value: any; // Prop value accessed in `richSelect.tsx`.

    onClick(): void;
}

export const RichSelectItem = (props: IRichSelectItemProps) => (
        <Flex
            align="center"
            gap="3"
            height={40}
            width={1}
            css={{
                cursor: 'pointer',
                px: '$3',
                '&:hover': {
                    background: '$gray100',
                    borderRadius: '$1'
                }
            }}
            onClick={props.onClick}
        >
            {props.isCheckbox && (
                <Checkbox state={props.isSelected ? 'checked' : 'unchecked'} onClick={noop} />
            )}

            <Box color="gray800" title={props.children} css={{ ellipsis: true, flex: '1' }}>
                {props.children}
            </Box>

            {!props.isCheckbox && props.isSelected && (
                <Box color="primary700">
                    <I icon="check" />
                </Box>
            )}
        </Flex>
    );
