import { Box } from 'common/src/designSystem/components/box';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

export const FieldName = (props: React.ComponentProps<typeof Box>) => {
    const defaultCSS: CSS = { overflow: 'hidden', textOverflow: 'ellipsis' };

    return (
        <Box
            color="gray800"
            fontSize="textSm"
            fontWeight="medium"
            // eslint-disable-next-line react/prop-types
            css={{ ...defaultCSS, ...props.css }}
        >
            {props.children}
        </Box>
    );
};
