import * as React from 'react';
import { Flex } from './flex';
import { I, IIcon, IStyle } from './i';
import { CSS, styled } from './stitches';

const _Badge = styled('div', {
    alignItems: 'center',
    borderRadius: '16px',
    display: 'flex',
    fontWeight: '$medium',
    gap: '$2',
    userSelect: 'none',
    height: 'fit-content',
    variants: {
        size: {
            sm: {
                height: '22px',
                fontSize: '12px',
                px: '8px'
            },
            md: {
                height: '24px',
                fontSize: '12px',
                px: '10px'
            },
            lg: {
                height: '28px',
                fontSize: '14px',
                px: '12px'
            }
        },
        color: {
            gray: {
                background: '$gray100',
                color: '$gray700'
            },
            primary: {
                background: '$primary50',
                color: '$primary700'
            },
            error: {
                background: '$error50',
                color: '$error700'
            },
            warning: {
                background: '$warning50',
                color: '$warning700'
            },
            success: {
                background: '$success50',
                color: '$success700'
            },
            pink: {
                background: '$pink50',
                color: '$pink700'
            },
            white: {
                background: 'white',
                border: '1px solid $gray300'
            }
        },
        cursor: {
            default: {
                cursor: 'default'
            },
            pointer: {
                cursor: 'pointer'
            },
            text: {
                cursor: 'text'
            }
        },
        ellipsis: {
            true: {
                maxWidth: '80%',
                '& .h-badge-content': {
                    ellipsis: true,
                    flex: '1'
                }
            }
        }
    },
    defaultVariants: {
        size: 'sm',
        color: 'gray',
        cursor: 'default'
    }
});

export type IBadgeSize = 'sm' | 'md' | 'lg';

export type IBadgeColor = 'gray' | 'primary' | 'error' | 'warning' | 'success' | 'pink' | 'white';

export type IBadgeCursor = 'default' | 'pointer' | 'text';

interface IBadgeProps {
    children?: React.ReactNode;
    color?: IBadgeColor;
    css?: CSS;
    cursor?: IBadgeCursor;
    ellipsis?: true;
    leftIcon?: IIcon;
    leftIconStyle?: IStyle;
    rightIcon?: IIcon;
    size?: IBadgeSize;

    onClick?(e: React.MouseEvent<HTMLDivElement>): void;
    onLeftIconClick?(e: React.MouseEvent<HTMLDivElement>): void;
    onContentClick?(e: React.MouseEvent<HTMLDivElement>): void;
    onRightIconClick?(e: React.MouseEvent<HTMLDivElement>): void;
}

export const Badge = React.forwardRef((props: IBadgeProps, ref: React.Ref<HTMLDivElement>) => (
        <_Badge
            ref={ref}
            size={props.size}
            color={props.color}
            cursor={props.cursor}
            ellipsis={props.ellipsis}
            onClick={props.onClick}
            css={props.css}
        >
            {props.leftIcon && (
                <Flex
                    align="center"
                    height={1}
                    css={{
                        cursor: props.onLeftIconClick ? 'pointer' : 'inherit'
                    }}
                    onClick={(e) => {
                        props.onLeftIconClick?.(e);
                    }}
                >
                    <I icon={props.leftIcon} iconStyle={props.leftIconStyle} />
                </Flex>
            )}

            {props.children && (
                <Flex
                    className="h-badge-content"
                    align="center"
                    height={1}
                    css={{ flex: '1' }}
                    onClick={(e) => {
                        props.onContentClick?.(e);
                    }}
                >
                    {props.children}
                </Flex>
            )}

            {props.rightIcon && (
                <Flex
                    align="center"
                    height={1}
                    css={{
                        cursor: props.onRightIconClick ? 'pointer' : 'inherit'
                    }}
                    onClick={(e) => {
                        props.onRightIconClick?.(e);
                    }}
                >
                    <I icon={props.rightIcon} />
                </Flex>
            )}
        </_Badge>
    ));
