import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { CSS, styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { IInputState } from './state';

const _StyledInputContainer = styled('div', {
    alignItems: 'center',
    borderRadius: '$1',
    display: 'flex',
    gap: '$space$2',
    padding: '0 $space$3',
    position: 'relative',
    width: '100%',
    '& input::placeholder': {
        color: '$gray500'
    },
    variants: {
        state: {
            active: {
                backgroundColor: 'white',
                border: '1px solid $gray300',
                color: '$gray900'
            },
            disabled: {
                background: '$gray50',
                border: '1px solid $gray300',
                color: '$gray500'
            },
            error: {
                backgroundColor: 'white',
                border: '1px solid $error500'
            },
            search: {
                backgroundColor: '$gray100',
                border: '1px solid $gray300',
                color: '$gray500'
            }
        },
        cursor: {
            default: {
                cursor: 'default'
            },
            text: {
                cursor: 'text'
            },
            pointer: {
                cursor: 'pointer'
            }
        },
        height: {
            fixed: {
                height: '40px'
            },
            fluid: {
                minHeight: '40px'
            }
        }
    },
    defaultVariants: {
        state: 'active',
        height: 'fixed'
    }
});

interface IStyledInputContainerProps {
    children: React.ReactNode;
    cursor: 'default' | 'text' | 'pointer';
    css?: CSS;
    height?: 'fixed' | 'fluid';
    icon?: IIcon;
    rightIcon?: IIcon;
    state?: IInputState;

    onClick?(e: React.MouseEvent<HTMLDivElement>): void;
    onRightIconClick?(e: React.MouseEvent<HTMLDivElement>): void;
}

export const StyledInputContainer = React.forwardRef(
    (props: IStyledInputContainerProps, ref: React.Ref<HTMLDivElement>) => (
            <_StyledInputContainer
                ref={ref}
                height={props.height}
                state={props.state}
                cursor={props.cursor}
                css={props.css}
                onClick={(e) => {
                    props.onClick?.(e);
                }}
            >
                {props.icon && (
                    <Flex css={{ color: '$gray500' }}>
                        <I icon={props.icon} />
                    </Flex>
                )}

                {props.children}

                {props.state === 'error' && (
                    <Flex
                        className="styled-input-container-circle-exclamation"
                        css={{
                            color: '$error500'
                        }}
                    >
                        <I icon="circle-exclamation" />
                    </Flex>
                )}

                {props.rightIcon && (
                    <Flex
                        css={{
                            color: '$gray500',
                            cursor:
                                typeof props.onRightIconClick === 'function' ? 'pointer' : 'inherit'
                        }}
                        onClick={(e) => {
                            props.onRightIconClick?.(e);
                        }}
                    >
                        <I icon={props.rightIcon} />
                    </Flex>
                )}
            </_StyledInputContainer>
        )
);
StyledInputContainer.displayName = "StyledInputContainer";