import { Box } from 'common/src/designSystem/components/box';
import { MapLayer, TraceFragment } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import {
    DEFAULT_CENTER,
    DEFAULT_ZOOM,
    getMapTypeId,
    ICoordinates,
    toGoogleCoordinates
} from 'common/src/util/map';
import { isEqual } from 'lodash-es';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { getIGNPlanLayer } from './ignLayers';

interface IMapProps {
    center?: ICoordinates;
    point?: ICoordinates;
    layer?: MapLayer | null;
    initialZoom?: number;
    coordinatesFieldsProps?: {
        latitudeProps: FieldRenderProps<number, HTMLInputElement>;
        longitudeProps: FieldRenderProps<number, HTMLInputElement>;
        zoomProps: FieldRenderProps<number, HTMLInputElement>;
    };
    traces?: TraceFragment[];
    mapOptions?: google.maps.MapOptions;

    onTilesloaded?(): void;
}

export const Map = React.memo((props: IMapProps) => {
    const divRef = React.useCallback((node) => {
        if (node) {
            if (map.current) {
                marker.current?.setMap(null);

                map.current = null;
            }

            map.current = new google.maps.Map(node, {
                zoom:
                    props.coordinatesFieldsProps?.zoomProps.input.value ??
                    props.initialZoom ??
                    DEFAULT_ZOOM,
                center: toGoogleCoordinates(props.center) || DEFAULT_CENTER,
                gestureHandling: 'cooperative',
                mapTypeControl: false,
                streetViewControl: false,
                mapTypeId: getMapTypeId(props.layer),
                ...(props.mapOptions || {})
            });

            map.current.mapTypes.set(MapLayer.IgnPlan, getIGNPlanLayer());

            map.current.addListener('zoom_changed', () => {
                if (props.coordinatesFieldsProps) {
                    props.coordinatesFieldsProps.zoomProps.input.onChange(map.current!.getZoom());
                }
            });

            map.current.addListener('tilesloaded', () => {
                props.onTilesloaded?.();
            });

            placeTraces();
        }
    }, []);
    const map = React.useRef<google.maps.Map | null>(null);
    const marker = React.useRef<google.maps.Marker | null>(null);
    const lines = React.useRef<google.maps.Polyline[]>([]);
    const placeMarker = () => {
        if (marker.current) {
            marker.current.setMap(null);
        }

        if (props.point && map.current) {
            const coordinates = toGoogleCoordinates(props.point)!;

            map.current.setCenter(coordinates);

            marker.current = new google.maps.Marker({
                position: coordinates,
                map: map.current,
                draggable:
                    typeof props.coordinatesFieldsProps === 'object' &&
                    props.coordinatesFieldsProps !== null
            });

            if (props.coordinatesFieldsProps) {
                marker.current.addListener('dragend', (event: any) => {
                    props.coordinatesFieldsProps?.latitudeProps.input.onChange(event.latLng.lat());
                    props.coordinatesFieldsProps?.longitudeProps.input.onChange(event.latLng.lng());
                });
            }
        }
    };
    const placeTraces = () => {
        lines.current.forEach((l) => l.setMap(null));

        if (isNonEmptyArray(props.traces)) {
            lines.current = props.traces.map((trace) => {
                const path = trace.points.map(([lat, lng]: number[]) => ({ lat, lng }));
                const line = new google.maps.Polyline({
                    path,
                    geodesic: true,
                    strokeColor: trace.color,
                    strokeOpacity: 1,
                    strokeWeight: 4
                });

                line.setMap(map.current);

                return line;
            });
        } else {
            lines.current = [];
        }
    };

    React.useEffect(() => {
        placeMarker();
    }, [JSON.stringify(props.point)]);

    React.useEffect(() => {
        map.current?.setMapTypeId(getMapTypeId(props.layer));
    }, [props.layer]);

    React.useEffect(() => {
        placeTraces();
    }, [JSON.stringify(props.traces || [])]);

    return (
        <Box height={1} width={1} ref={divRef}>
            {props.coordinatesFieldsProps?.zoomProps && (
                <OnChange name={props.coordinatesFieldsProps.zoomProps.input.name}>
                    {(newZoom: number) => {
                        map.current?.setZoom(newZoom);
                    }}
                </OnChange>
            )}
        </Box>
    );
}, isEqual);
Map.displayName = "Map";
