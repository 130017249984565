import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CountriesService } from 'common/src/services/countriesService';
import { toArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString, normalize } from 'common/src/util/string';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useUniqueIds } from '../../hooks/useUniqueIds';
import { Dropdown } from './dropdown/dropdown';
import { Item } from './dropdown/item';
import { Menu } from './dropdown/menu';
import { Trigger } from './dropdown/trigger';
import { BaseInputProps } from './input/baseInputProps';
import { Description } from './input/description';
import { Hint } from './input/hint';
import { Label } from './input/label';
import { StyledInputContainer } from './input/styledInputContainer';
import { Separator } from './separator';

export type PhoneInputValue = {
    country: string;
    code: string;
    number: string;
};

interface IPhoneInputProps extends BaseInputProps {
    value: PhoneInputValue;

    onChange(value: PhoneInputValue): void;
}

export const PhoneInput = (props: IPhoneInputProps) => {
    const translate = useTranslate();
    const rootRef = React.useCallback((node: HTMLDivElement | null) => {
        if (node) {
            setDropdownWidth(node.getBoundingClientRect().width);
        }
    }, []);
    const searchRef = React.useRef<HTMLInputElement | null>(null);
    const [dropdownWidth, setDropdownWidth] = React.useState(200);
    const [isOpen, setIsOpen] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const countriesService = useService(CountriesService);
    const countries = React.useMemo(() => sortBy(
            countriesService.countries.flatMap((country) => toArray(country.phoneCode).map((phoneCode) => ({
                        ...country,
                        phoneCode
                    }))),
            ({ name }) => normalize(name)
        ), []);
    const currentCountry = React.useMemo(() => countries.find(({ code, phoneCode }) => code === props.value.country && phoneCode === props.value.code), [props.value.country, props.value.code]);
    const displayedCountries = React.useMemo(() => countries.filter((country) => isNonEmptyString(search)
                ? country.name.toLowerCase().includes(search.trim().toLowerCase()) ||
                      country.phoneCode.includes(search.trim())
                : true), [countries, search]);
    const displayedCountriesElements = React.useMemo(() => displayedCountries.map((country, index) => (
                <Item
                    key={index}
                    onClick={() => {
                        props.onChange({
                            ...props.value,
                            country: country.code,
                            code: country.phoneCode
                        });

                        setSearch('');
                        setIsOpen(false);
                    }}
                >
                    <Flex gap="2" width={1}>
                        <Box>{country.emoji}</Box>
                        <Box
                            css={{
                                ellipsis: true
                            }}
                        >
                            {country.name}
                        </Box>
                        <Box color="gray500">{country.phoneCode}</Box>
                    </Flex>
                </Item>
            )), [displayedCountries, props.value, props.onChange]);
    const { inputId, descId, errorId } = useUniqueIds();

    return (
        <Flex ref={rootRef} css={props.css} direction="column" width={1}>
            <Label htmlFor={inputId}>{props.label}</Label>

            <Description id={descId}>{props.description}</Description>

            {(props.label || props.description) && <Spacer height="1" />}

            <Flex>
                <Dropdown
                    isOpen={isOpen}
                    onStateChange={(newIsOpen) => {
                        setIsOpen(newIsOpen);

                        if (newIsOpen) {
                            setTimeout(() => {
                                searchRef.current?.focus();
                            }, 100);
                        }
                    }}
                >
                    <Trigger>
                        <StyledInputContainer
                            cursor="pointer"
                            rightIcon={isOpen ? 'chevron-up' : 'chevron-down'}
                            state={props.state}
                            css={{
                                borderRight: 'none',
                                borderBottomRightRadius: '0',
                                borderTopRightRadius: '0',
                                userSelect: 'none',
                                width: '100px',
                                '& .styled-input-container-circle-exclamation': {
                                    display: 'none'
                                }
                            }}
                        >
                            {currentCountry && (
                                <Box width={1}>
                                    {currentCountry.emoji} {currentCountry.phoneCode}
                                </Box>
                            )}
                        </StyledInputContainer>
                    </Trigger>

                    <Menu placement="bottom-start" renderOnPortal={true} width={dropdownWidth}>
                        <Flex
                            direction="column"
                            width={1}
                            height={Math.min(201, displayedCountries.length * 40 + 41)}
                        >
                            <Box
                                height={40}
                                css={{
                                    padding: '$2 $4',
                                    '& input': {
                                        background: 'transparent',
                                        border: 'none',
                                        color: '$gray800',
                                        height: '100%',
                                        outline: 'none',
                                        width: '100%'
                                    },
                                    '& input::placeholder': {
                                        color: '$gray500',
                                        opacity: 1
                                    },
                                    '& input::-ms-input-placeholder ': {
                                        color: '$gray500',
                                        opacity: 1
                                    }
                                }}
                            >
                                <input
                                    ref={searchRef}
                                    aria-label={translate('indicatif_t_l_p_02025')}
                                    type="text"
                                    placeholder={translate('rechercher_un_p_04678')}
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value);
                                    }}
                                />
                            </Box>

                            <Separator direction="horizontal" color="gray100" />

                            <Flex
                                direction="column"
                                css={{
                                    height: 'calc(100% - 41px)',
                                    overflowY: 'auto'
                                }}
                            >
                                {displayedCountriesElements}
                            </Flex>
                        </Flex>
                    </Menu>
                </Dropdown>

                <StyledInputContainer
                    cursor="text"
                    state={props.state}
                    css={{
                        borderBottomLeftRadius: '0',
                        borderTopLeftRadius: '0',
                        flex: '1'
                    }}
                >
                    <Box
                        css={{
                            flex: '1',
                            height: '100%',
                            '& input': {
                                background: 'transparent',
                                border: 'none',
                                color: '$gray800',
                                height: '100%',
                                outline: 'none',
                                width: '100%'
                            },
                            '& input::placeholder': {
                                color: '$gray500',
                                opacity: 1
                            },
                            '& input::-ms-input-placeholder ': {
                                color: '$gray500',
                                opacity: 1
                            }
                        }}
                    >
                        <input
                            autoComplete="tel-national"
                            id={inputId}
                            aria-describedby={props.description ? descId : undefined}
                            aria-invalid={props.state === 'error'}
                            aria-errormessage={props.state === 'error' ? errorId : undefined}
                            type="tel"
                            placeholder={props.placeholder}
                            disabled={props.state === 'disabled'}
                            value={props.value.number}
                            onChange={(e) => {
                                props.onChange({
                                    ...props.value,
                                    number: e.target.value
                                });
                            }}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                        />
                    </Box>
                </StyledInputContainer>
            </Flex>

            <Hint id={errorId} state={props.state}>
                {props.hint}
            </Hint>
        </Flex>
    );
};
