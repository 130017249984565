import { Box } from 'common/src/designSystem/components/box';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import {
    FORM_FORMAT,
    formatForForm,
    LocaleFormats,
    parseFromForm,
    UNSUPPORTED_DATE_FORMAT
} from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { Label } from '../label/label';
import { fieldSubscriptions } from '../subscriptions';
import { Subtitle } from './subtitle';
import { _OldInputStyled } from './text';

const isDateSupported = (() => {
    try {
        const input = document.createElement('input');
        input.type = 'date';
        input.value = 'Hello World';

        return input.type === input.getAttribute('type') && input.value !== 'Hello World';
    } catch {
        return false;
    }
})();

interface IDateComponentProps extends FieldRenderProps<DateTime, HTMLElement> {
    isFilter: boolean;
    label?: string;
    placeholder: string;
    min?: string;
    max?: string;
    isOptional?: boolean;
    subtitle?: string;
}

const DateComponent = (props: IDateComponentProps) => {
    const translate = useTranslate();
    const [textValue, setTextValue] = React.useState(
        props.input.value?.isValid ? props.input.value.toFormat(UNSUPPORTED_DATE_FORMAT) : ''
    );
    const [type, setType] = React.useState(props.isFilter ? 'text' : 'date');
    const invalid =
        (props.meta.data?.forceDisplayError && props.meta.invalid) ||
        (props.meta.touched && props.meta.invalid);
    const getValue = () => {
        if (!isDateSupported) {
            return textValue;
        } else if (type === 'text') {
            return props.input.value?.isValid
                ? props.input.value.toLocaleString(LocaleFormats.DateOnly.Numeric)
                : '';
        } else {
            return formatForForm(props.input.value);
        }
    };

    return (
        <div>
            {props.label && (
                <Label htmlFor={props.input.name} isOptional={props.isOptional}>
                    {props.label}
                </Label>
            )}

            {isNonEmptyString(props.subtitle) && <Subtitle text={props.subtitle} />}

            <_OldInputStyled
                type={type}
                name={props.input.name}
                invalid={invalid}
                filters={props.isFilter}
                placeholder={
                    isDateSupported ? props.placeholder : translate('entrez_une_date_25261')
                }
                min={props.min}
                max={props.max}
                onFocus={() => {
                    if (isDateSupported) {
                        setType('date');
                    }
                }}
                onBlur={() => {
                    if (props.isFilter) {
                        setType('text');
                    }
                }}
                onChange={(e) => {
                    setTextValue(e.target.value);

                    props.input.onChange(
                        parseFromForm(
                            e.target.value,
                            isDateSupported ? FORM_FORMAT : UNSUPPORTED_DATE_FORMAT
                        )
                    );
                }}
                value={getValue()}
            />

            {invalid && (
                <Box
                    css={{
                        color: '$oldRed',
                        marginBottom: '$6'
                    }}
                >
                    {props.meta.error}
                </Box>
            )}
        </div>
    );
};

interface IDateInputProps {
    label: string;
    name: string;
    placeholder?: string;
    min?: string;
    max?: string;
    isOptional?: boolean;
    subtitle?: string;
}

export const DateInput = (props: IDateInputProps) => (
    <Field
        {...props}
        subscription={fieldSubscriptions}
        render={(renderProps) => (
            <DateComponent
                {...renderProps}
                isFilter={false}
                label={props.label}
                placeholder={props.placeholder ?? props.label}
                min={props.min}
                max={props.max}
            />
        )}
    />
);

interface IDateInputFiltersProps {
    name: string;
    placeholder: string;
    min?: string;
    max?: string;
}

export const DateInputFilter = (props: IDateInputFiltersProps) => (
    <Field
        {...props}
        subscription={fieldSubscriptions}
        render={(renderProps) => (
            <DateComponent
                {...renderProps}
                isFilter={true}
                placeholder={props.placeholder}
                min={props.min}
                max={props.max}
            />
        )}
    />
);
